<template>
  <div>
    <mia-navback @back="back" :isShowSave="!isView" @submit="submitForm" />
    <div class="header">
      <el-form :inline="true">
        <el-form-item
          label="记录日期:"
          :style="{
            'text-align': 'left',
            width: placeLedger.updateUserName ? '25%' : '50%',
          }"
        >
          <el-date-picker
            v-model="placeLedger.recordDate"
            :disabled="isView"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="台账编号:"
          :style="{
            'text-align': placeLedger.updateUserName ? 'left' : 'right',
            width: placeLedger.updateUserName ? '25%' : '50%',
          }"
        >
          {{ placeLedger.no }}
        </el-form-item>
        <el-form-item
          v-if="placeLedger.updateDate"
          label="修改时间:"
          style="text-align: left; width: 25%"
        >
          {{ placeLedger.updateDate }}
        </el-form-item>
        <el-form-item
          v-if="placeLedger.updateUserName"
          label="最后修改人:"
          style="text-align: left; width: 25%"
        >
          {{ placeLedger.updateUserName }}
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <table>
        <caption style="font-size: 24px; font-weight: 600; margin-bottom: 20px">
          经营场所和仓库管理记录
        </caption>
        <tbody>
          <tr>
            <td colspan="10" style="font-size: 20px; font-weight: 600">
              农药产品经营场所和仓库管理通用信息
            </td>
          </tr>
          <tr>
            <td :colspan="isView ? 1 : 2">农药名称</td>
            <td>
              <el-input
                style="width: 100%"
                v-model="placeLedger.name"
                type="text"
                :disabled="isView"
              ></el-input>
            </td>
            <td>类别</td>
            <td colspan="2">
              <el-select
                v-model="placeLedger.type"
                :disabled="isView"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, Index) in productTypes"
                  :key="Index"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </td>
            <td colspan="2">规格</td>
            <td colspan="2">
              <el-input
                style="width: 100%"
                v-model="placeLedger.spec"
                type="text"
                :disabled="isView"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td :colspan="isView ? 1 : 2">进货日期</td>
            <td style="text-align: left">
              <el-date-picker
                style="width: 150px"
                v-model="placeLedger.purchaseDate"
                :disabled="isView"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </td>
            <td>进货数量</td>
            <td colspan="2">
              <el-input
                style="width: 100%"
                v-model="placeLedger.purchaseNum"
                type="number"
                :disabled="isView"
              ></el-input>
            </td>
            <td colspan="2">质量保证期至</td>
            <td colspan="2">
              <el-date-picker
                style="width: 150px"
                v-model="placeLedger.shelfLife"
                :disabled="isView"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="10" style="font-size: 20px; font-weight: 600">
              经营场所管理记录
            </td>
          </tr>
          <tr>
            <td rowspan="2">序号</td>
            <td v-if="!isView" rowspan="2" style="width: 120px">操作</td>
            <td rowspan="2">经营场所补货时间</td>
            <td rowspan="2">补货来源</td>
            <td rowspan="2">补货数量</td>
            <td rowspan="2">销售情况</td>
            <td colspan="4">清出情况及处置情况</td>
          </tr>
          <tr>
            <td>清出时间</td>
            <td>数量</td>
            <td>原因</td>
            <td>处置</td>
          </tr>
          <tr v-for="(place, index) in placeList" :key="'place' + index">
            <td>
              {{ index + 1 }}
            </td>
            <td v-if="!isView">
              <el-button type="text" @click="addPlace">插入</el-button>
              <el-button
                v-if="placeList.length > 1"
                type="text"
                @click="delPlace(index)"
                >删除</el-button
              >
            </td>
            <td>
              <el-date-picker
                style="width: 150px"
                v-model="place.replenishDate"
                :disabled="isView"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="place.replenishSource"
                type="text"
                :disabled="isView"
              ></el-input>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="place.replenishNum"
                type="number"
                :disabled="isView"
              ></el-input>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="place.saleInfo"
                type="text"
                :disabled="isView"
              ></el-input>
            </td>
            <td>
              <el-date-picker
                style="width: 150px"
                v-model="place.clearDate"
                :disabled="isView"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="place.clearNum"
                type="number"
                :disabled="isView"
              ></el-input>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="place.clearReason"
                type="text"
                :disabled="isView"
              ></el-input>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="place.disposeInfo"
                type="text"
                :disabled="isView"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="10" style="font-size: 20px; font-weight: 600">
              仓库管理记录
            </td>
          </tr>
          <tr>
            <td rowspan="2">序号</td>
            <td v-if="!isView" rowspan="2" style="width: 120px">操作</td>
            <td rowspan="2">入库时间</td>
            <td rowspan="2">数量</td>
            <td colspan="6">出库及清出库存情况</td>
          </tr>
          <tr>
            <td colspan="2">出库时间</td>
            <td>数量</td>
            <td>出库原因</td>
            <td>出库去向</td>
            <td>存库数</td>
          </tr>
          <tr v-for="(wms, index) in wmsList" :key="'wms' + index">
            <td>
              {{ index + 1 }}
            </td>
            <td v-if="!isView">
              <el-button type="text" @click="addWms">插入</el-button>
              <el-button
                v-if="wmsList.length > 1"
                type="text"
                @click="delWms(index)"
                >删除</el-button
              >
            </td>
            <td>
              <el-date-picker
                style="width: 150px"
                v-model="wms.purchaseDate"
                :disabled="isView"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="wms.purchaseNum"
                type="number"
                :disabled="isView"
              ></el-input>
            </td>
            <td colspan="2">
              <el-date-picker
                style="width: 150px"
                v-model="wms.saleDate"
                :disabled="isView"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="wms.saleNum"
                type="number"
                :disabled="isView"
              ></el-input>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="wms.saleReason"
                type="text"
                :disabled="isView"
              ></el-input>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="wms.saleWhither"
                type="text"
                :disabled="isView"
              ></el-input>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="wms.inventoryNum"
                type="number"
                :disabled="isView"
              ></el-input>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <div style="display: inline-block; width: 50%; text-align: left">
          <span>经营场所管理人签名：</span>
          <div v-if="isView" style="display: inline-block">
            <el-image
              style="width: 100px; height: 100px"
              :src="getImg(placeLedger.placeSign)"
            ></el-image>
          </div>
          <div v-else @click="openSign" style="display: inline-block">
            <div v-if="!placeLedger.placeSign" class="sign">点击签名</div>
            <el-image
              v-else
              style="width: 100px; height: 100px"
              :src="getImg(placeLedger.placeSign)"
            ></el-image>
          </div>
        </div>
        <div style="display: inline-block; width: 50%; text-align: right">
          <span>仓库管理员签名：</span>
          <div v-if="isView" style="display: inline-block">
            <el-image
              style="width: 100px; height: 100px"
              :src="getImg(placeLedger.wmsSign)"
            ></el-image>
          </div>
          <div v-else @click="openWmsSign" style="display: inline-block">
            <div v-if="!placeLedger.wmsSign" class="sign">点击签名</div>
            <el-image
              v-else
              style="width: 100px; height: 100px"
              :src="getImg(placeLedger.wmsSign)"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
    <Sign
      :visible="signatureVisible"
      @finsih="signFisish"
      @close="signatureVisible = false"
    ></Sign>
    <Sign
      :visible="wmsSignatureVisible"
      @finsih="wmsSignFisish"
      @close="wmsSignatureVisible = false"
    ></Sign>
  </div>
</template>
<script>
import Sign from "@/components/Sign";
import { uploadFile } from "@/api/common";
import { imgUrl } from "../../../../settting";
import {
  getPlaceLedgerCode,
  getPlaceLedgerDetails,
  addPlaceLedger,
  editPlaceLedger,
} from "@/api/placeLedger";
import { GetProductTypeList } from "@/api/productType";
import moment from "moment";
export default {
  components: { Sign },
  data() {
    return {
      placeLedger: {},
      checkPlace: [],
      isView: false,
      signatureVisible: false,
      wmsSignatureVisible: false,
      productTypes: [],
      placeList: [
        {
          replenishDate: moment().format("YYYY-MM-DD"),
          replenishSource: "",
          replenishNum: "",
          saleInfo: "",
          clearDate: moment().format("YYYY-MM-DD"),
          clearReason: "",
          clearNum: "",
          disposeInfo: "",
        },
      ],
      wmsList: [
        {
          purchaseDate: moment().format("YYYY-MM-DD"),
          purchaseNum: "",
          saleDate: moment().format("YYYY-MM-DD"),
          saleNum: "",
          saleReason: "",
          saleWhither: "",
          inventoryNum: "",
        },
      ],
      wmsSign: "",
    };
  },
  mounted() {
    this.placeLedger.recordDate = moment().format("YYYY-MM-DD");
    this.placeLedger.purchaseDate = moment().format("YYYY-MM-DD");
    this.getProductTypeList();
  },
  methods: {
    init(id, view) {
      if (id) {
        getPlaceLedgerDetails(id).then((res) => {
          if (res.code === 1) {
            this.placeLedger = res.data;
            this.placeList = res.data.placeList;
            this.wmsList = res.data.wmsList;
            this.isView = view;
          }
        });
      } else {
        getPlaceLedgerCode().then((res) => {
          if (res.code == 1) {
            this.placeLedger = {
              ...this.placeLedger,
              no: res.data,
              checkDate: moment().format("YYYY-MM-DD"),
            };
          }
        });
      }
    },
    getProductTypeList() {
      GetProductTypeList({ pageNum: 1, pageSize: 50 }).then((res) => {
        if (res.code === 1) {
          this.productTypes = res.data.records.filter(
            (v) => v.name == "农药"
          )[0].list;
        }
      });
    },
    getImg(url) {
      return imgUrl + url;
    },
    valChange(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.value = val;
          }
          return v;
        });
      } else {
        if (val === "1") {
          this.safeItems = this.safeItems.map((v) => {
            if (name === v.name) {
              v.method = "0";
            }
            return v;
          });
        }
      }
    },
    val1Change(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.method = val;
          }
          return v;
        });
      }
    },
    addPlace() {
      const last = this.placeList[this.placeList.length - 1];
      if (!last.replenishSource) {
        return this.$message.error("请填写补货来源");
      }
      if (!last.replenishNum) {
        return this.$message.error("请填写补货数量");
      }
      if (!last.saleInfo) {
        return this.$message.error("请填写销售情况");
      }

      if (!last.clearReason) {
        return this.$message.error("请填写清出原因");
      }
      if (!last.clearNum) {
        return this.$message.error("请填写清出数量");
      }
      if (!last.disposeInfo) {
        return this.$message.error("请填写处置");
      }
      this.placeList.push({
        replenishDate: moment().format("YYYY-MM-DD"),
        replenishSource: "",
        replenishNum: "",
        saleInfo: "",
        clearDate: moment().format("YYYY-MM-DD"),
        clearReason: "",
        clearNum: "",
        disposeInfo: "",
      });
    },
    delPlace(index) {
      this.placeList.splice(index, 1);
    },
    addWms() {
      const last = this.wmsList[this.wmsList.length - 1];

      if (!last.purchaseNum) {
        return this.$message.error("请填写入库数量");
      }
      if (!last.saleNum) {
        return this.$message.error("请填写出库数量");
      }

      if (!last.saleReason) {
        return this.$message.error("请填写出库原因");
      }
      if (!last.saleWhither) {
        return this.$message.error("请填写出库去向");
      }
      if (!last.inventoryNum) {
        return this.$message.error("请填写存库数");
      }
      this.wmsList.push({
        purchaseDate: moment().format("YYYY-MM-DD"),
        purchaseNum: "",
        saleDate: moment().format("YYYY-MM-DD"),
        saleNum: "",
        saleReason: "",
        saleWhither: "",
        inventoryNum: "",
      });
    },
    delWms(index) {
      this.wmsList.splice(index, 1);
    },
    back() {
      this.$emit("back");
    },
    checkPlaceChange(val) {
      this.checkPlace = val;
    },
    submitForm() {
      if (!this.placeLedger.name) {
        return this.$message.error("请填写农药名称");
      }
      if (!this.placeLedger.type) {
        return this.$message.error("请选择类别");
      }
      if (!this.placeLedger.spec) {
        return this.$message.error("请填写规格");
      }
      if (!this.placeLedger.purchaseNum) {
        return this.$message.error("请填写进货数量");
      }
      const palceLast = this.placeList[this.placeList.length - 1];
      if (!palceLast.replenishSource) {
        return this.$message.error("请填写补货来源");
      }
      if (!palceLast.replenishNum) {
        return this.$message.error("请填写补货数量");
      }
      if (!palceLast.saleInfo) {
        return this.$message.error("请填写销售情况");
      }

      if (!palceLast.clearReason) {
        return this.$message.error("请填写清出原因");
      }
      if (!palceLast.clearNum) {
        return this.$message.error("请填写清出数量");
      }
      if (!palceLast.disposeInfo) {
        return this.$message.error("请填写处置");
      }
      // 仓库管理记录检验
      const wmslast = this.wmsList[this.wmsList.length - 1];

      if (!wmslast.purchaseNum) {
        return this.$message.error("请填写入库数量");
      }
      if (!wmslast.saleNum) {
        return this.$message.error("请填写出库数量");
      }

      if (!wmslast.saleReason) {
        return this.$message.error("请填写出库原因");
      }
      if (!wmslast.saleWhither) {
        return this.$message.error("请填写出库去向");
      }
      if (!wmslast.inventoryNum) {
        return this.$message.error("请填写存库数");
      }

      if (!this.placeLedger.placeSign) {
        return this.$message.error("请经营场所管理人签字");
      }
      if (!this.placeLedger.wmsSign) {
        return this.$message.error("请仓库管理员签字");
      }

      this.placeLedger.placeList = this.placeList;
      this.placeLedger.wmsList = this.wmsList;

      if (this.placeLedger.id) {
        editPlaceLedger(this.placeLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        addPlaceLedger(this.placeLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    openSign() {
      this.signatureVisible = true;
    },
    signFisish(val) {
      const imgs = this.base64ImgtoFile(val);
      const formData = new FormData();
      formData.append("file", imgs);
      uploadFile(formData).then((res) => {
        if (res.code === 1) {
          this.placeLedger = { ...this.placeLedger, placeSign: res.url };
        }
      });
      this.signatureVisible = false;
    },
    openWmsSign() {
      this.wmsSignatureVisible = true;
    },
    wmsSignFisish(val) {
      const imgs = this.base64ImgtoFile(val);
      const formData = new FormData();
      formData.append("file", imgs);
      uploadFile(formData).then((res) => {
        if (res.code === 1) {
          this.placeLedger = { ...this.placeLedger, wmsSign: res.url };
        }
      });
      this.wmsSignatureVisible = false;
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 80%;
  margin: 0 auto;
  padding: 20px 50px;
  /deep/ .el-form-item {
    margin-right: 0;
  }
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  color: #409eff;
}
.content {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
table {
  width: 100%;
}
.inline-table {
  border: 0px;
}
table,
td {
  border-collapse: collapse;
  border: 1px solid #999;
}
td {
  padding: 15px;
  font-size: 14px;
}
.sign {
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
}
</style>
