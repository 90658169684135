import request from '@/until/request.js'
const prefixName = '/gm/webLedgerPlaceWms'
// 退货单
export function getPlaceLedgerList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: { ...data }
  })
}
// 检查退单号id
export function getPlaceLedgerCode(){
  return request({
    url: prefixName + '/getCode',
    method: 'get'
  })
}
// 检查退单号id
export function getPlaceLedgerDetails(id){
  return request({
    url: prefixName + '/details',
    method: 'get',
    params: { id }
  })
}
// 退货单仓库商品列表
export function addPlaceLedger(data){
  return request({
    url: prefixName,
    method: 'post',
    data
  })
}
// 退货单仓库商品列表
export function editPlaceLedger(data){
  return request({
    url: prefixName,
    method: 'put',
    data
  })
}
// 删除零售单
export function delPlaceLedger(id) {
  return request({
      url: prefixName + '/' + id,
      method: 'delete'
  })
}


export function placeLedgerExport(id){
  return request({
    url: prefixName+'/export',
    method: 'get',
    responseType: 'blob',
    params: { id }
  })
}